import React from 'react';
import { Link } from 'react-router-dom';
import VideoSection from '../../../components/VideoSection';
function InstallMacosApp() {
	return (
		<div>
			<h2>Install MacOS App</h2>
			<p className='installDescription'>the macos app provides a user friendly way to launch WireHydra.</p>
			<p className='installDescription'>Download the MacOS app bundle from the <Link to="/downloads" className='downloadLink'>downloads</Link> page.</p>
			<p className='installDescription'>Once downloaded, save the app bundle to your Applications folder.</p>
			<p className='installDescription'>Open the application</p>
			<p className='installDescription'>Follow the onscreen prompts to set up your application</p>
			<VideoSection
				title="Launching a Server"
				description="Quickly launch your server directly from the app, no command line experience required."
				videoFilePath="00_A_appLaunch.mp4"
			/>
		</div>
	);
}

export default InstallMacosApp;