import React from 'react';
import styles from './ClickableImage.module.css';

const AppIcon = ({ imagePath, title, description, link }) => {
  const handleClick = () => {
    window.location.href = link;
  };

  return (
    <div
      className={styles.clickableContainer}
      onClick={handleClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => e.key === 'Enter' && handleClick()}
    >
      <img src={imagePath} alt={title} className={styles.image} />
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

export default AppIcon;
