// src/pages/Home.js
import './Home.css';
import { Link } from 'react-router-dom';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Home() {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

  return (
    <div className="App">
		<section id="home" class="parallax hero">
			<div class="overlay">
				<div class="container">
					<h2>WireHydra</h2>
					<p>Self-hosting files made simple.</p>
					<Link to="/documentation" class="btn">Get Started</Link>
				</div>
			</div>
		</section>

		<section id="about" class="parallax">
			<div class="container">
				<h2>File Management shouldn't be a <span class="highlight">monster</span> of a job</h2>
				<p>Spin up a self-hosted file server in seconds. Integrates with the auth systems you already use.</p>
				<p>You don't have time to monitor a cloud service or spend days configuring settings:<br />Wirehydra is designed for small IT teams servicing Large employee pools</p>
				<p>Simple API allows for easy integration into the pipeline software you already use.</p>
				<p>Self hosted systems mean you are the only one who decides when and where your data is accessible.</p>
			</div>
		</section>

		

		<section id="features" class="parallax">
			<div class="spacer"></div>
			<div class="container">
				<h2>Features</h2>
				<div class="feature-list">
					<div class="feature-item">
						<h4>File Management: Simplified</h4>
						<p>Easily host your files from a local folder or shared drive.</p>
					</div>
					<div class="feature-item">
						<h4>External Auth Integration</h4>
						<p>Manage your users via Ldap or Radius.</p>
					</div>
					<div class="feature-item">
						<h4>Automated Workflows</h4>
						<p>Create automated workflows that allow you to manage your productions</p>
					</div>
				</div>
			</div>
		</section>

		<section id="pricing" class="parallax">
			<div class="container">
				<h2>BETA Program</h2>
				<p>while we are in beta, all features are free to use.</p>
				
			</div>
		</section>

		<section id="closing" class="parallax">
			<div class="container">
				<h2>Get Started Today</h2>
				<p>Download WireHydra today and <span class="highlight">conquer</span> file management.</p>
				<Link to="/documentation" class="btn">Get Started</Link>
			</div>
		</section>
	</div>
  );
}

export default Home;
