import styles from './VideoSection.module.css';

function ImageSection({ title, description, imagePath, subtext }) {
  return (
    <div>
      <h2>{title}</h2>
      <p className={styles.videoSectionDescription}>{description}</p>
	  <img className={styles.videoPlayer}
		src={imagePath}
	  />
	  <p className={styles.videoSectionDescription}>{subtext}</p>
    </div>
  );
}

export default ImageSection;
