//src/components/Footer.js

import React from 'react';
import './Footer.css';
function Footer() {
	  return (
	<footer>
	  <p>© 2021 WireHydra</p>
	</footer>
  );
}

export default Footer;