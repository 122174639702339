import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './Dropdown.css'; // Basic styling

function Dropdown({ label, items }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleItemClick = () => {
    setIsOpen(false); // Close dropdown on item click
  };

  return (
    <div
      className="dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button className="dropdown-label">{label}</button>
      {isOpen && (
        <ul className="dropdown-menu">
          {items.map((item, index) => (
            <li key={index} className="dropdown-item">
              <Link to={item.href} onClick={handleItemClick}>{item.label}</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string.isRequired, // Label for the dropdown (e.g., "Resources")
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired, // Text to display for the item
      href: PropTypes.string.isRequired,  // Link to navigate to
    })
  ).isRequired,
};

export default Dropdown;
