// src/pages/Documentation.js

import InstallTabView from './install/InstallTabView';
import VideoSection from '../components/VideoSection';
import ImageSection from '../components/ImageSection';



import Sidebar from '../components/SideBar';
import styles from './Documentation.module.css';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


function Documentation() {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);
	
  const sidebarLinks = [
    { id: 'about', label: 'About', type: 'section' },
    { id: 'setup', label: 'Quickstart', type: 'section' },
	{ id: 'install', label: 'Install', type: 'subsection', section: 'setup' },
    { id: 'license-activation', label: 'License Activation', type: 'subsection', section: 'setup' },
	{ id: 'mouting-server', label: 'Mounting a Server', type: 'subsection', section: 'setup' },
	{ id: 'creating-users', label: 'Creating Users', type: 'subsection', section: 'setup' },
	{ id: 'creating-permissions', label: 'Creating Permissions', type: 'subsection', section: 'setup' },
	{ id: 'setting-access-control', label: 'Access Policies', type: 'subsection', section: 'setup' },
	{ id: 'creating-api-keys', label: 'Creating API Keys', type: 'subsection', section: 'setup' },
	{ id: 'setting-up-external-auth', label: 'External Auth', type: 'subsection', section: 'setup' },
    { id: 'admin', label: 'Admin Panel', type: 'section' },
	{ id: 'about-admin', label: 'about', type: 'subsection', section: 'admin' },
    { id: 'users', label: 'Users', type: 'subsection', section: 'admin' },
	{ id: 'external-auth', label: 'External Auth', type: 'subsection', section: 'admin' },
    { id: 'server-mounts', label: 'Server Mounts', type: 'subsection', section: 'admin' },
    { id: 'permissions', label: 'Permission', type: 'subsection', section: 'admin' },
    { id: 'access-control', label: 'Access Control', type: 'subsection', section: 'admin' },
    { id: 'general', label: 'General Settings', type: 'subsection', section: 'admin' },
    { id: 'browser', label: 'File Browser', type: 'section' },
	{ id: 'about-browser', label: 'about', type: 'subsection', section: 'browser' },
    { id: 'servers-page', label: 'Server List', type: 'subsection', section: 'browser' },
    
	// { id: 'api', label: 'API', type: 'section' },
    // { id: 'upload', label: 'Upload', type: 'subsection', section: 'api' },
    // { id: 'download', label: 'Download', type: 'subsection', section: 'api' },
    // { id: 'delete', label: 'Delete', type: 'subsection', section: 'api' },
    // { id: 'rename', label: 'Rename', type: 'subsection', section: 'api' },
    // { id: 'move', label: 'Move', type: 'subsection', section: 'api' },
    // { id: 'copy', label: 'Copy', type: 'subsection', section: 'api' },
    // { id: 'search', label: 'Search', type: 'subsection', section: 'api' },
    // { id: 'preview', label: 'Preview', type: 'subsection', section: 'api' },
    // { id: 'share', label: 'Share', type: 'subsection', section: 'api' },
	// { id: 'sample-project', label: 'Sample Project', type: 'section' },
	// { id: 'about-sample', label: 'about', type: 'subsection', section: 'sample-project' },
	// { id: 'setup', label: 'setup', type: 'subsection', section: 'sample-project' },
	// { id: 'cleanup', label: 'cleanup', type: 'subsection', section: 'sample-project' },
  ];

  return (
    <div className={styles.content}>
      <Sidebar links={sidebarLinks} />
      <div className={styles.sections}>
		<section id="about" className={[styles.scrollableContent, styles.titleSection].join(' ')}>
			<ImageSection
				title="About WireHydra"
				description="WireHydra is a file hosting tool that allows you to spin up an authenticated file-server in minutes."
				imagePath=""
				subtext="This documentation will guide you through the setup process of launching your first server, creating users, and setting up permissions."
			/>
		</section>
        <section id="install" className={styles.scrollableContent}>
          <InstallTabView />
        </section>
		<section id="license-activation" className={styles.scrollableContent}>
		  <VideoSection 
		  	title="Activate your License"
			description="You will need to activate a license before you can use WireHydra. While in beta, all features are free to use." 
			videoFilePath="00_B_licensingWirehydra.mp4"
		  />
		</section>	
		<section id="mouting-server" className={styles.scrollableContent}>
			<VideoSection
				title="Mounting a Server"
				description="Servers are the core component of WireHydra. Each server can have its own set of users, permissions, and access control policies. Servers can be mounted from a local folder or a shared SMB or NFS drive."
				videoFilePath="01_mountingServer.mp4"
			/>
		</section>
		<section id="creating-users" className={styles.scrollableContent}>
			<VideoSection
				title="Creating Users"
				description="Let's create your first non-admin user - a generic employee."
				videoFilePath="02_creatingUsers_A_firstUser.mp4"
			/>
			<VideoSection
				title=""
				description="Let's create a second user, this time a manager."
				videoFilePath="02_creatingUsers_B_secondUser.mp4"
			/>
		</section>
		<section id="creating-permissions" className={styles.scrollableContent}>
			<VideoSection
				title="Creating Permissions"
				description="Permissions allow you to control what users can do within WireHydra. We'll create separate permissions for employees and managers."
				videoFilePath="03_permissions.mp4"
			/>
		</section>
		<section id="setting-access-control" className={styles.scrollableContent}>
			<VideoSection
				title="Setting Access Policies"
				description="Access Control policies allow you to control which users can access which servers, and what permissions they have on those servers. We'll want to set different policies for employees and managers."
				videoFilePath="04_accessControl.mp4"
			/>
			<VideoSection
				title="Multi-Server Access"
				description="We've given users different levels of access to our initial server, but what if we want to have one server for all employees and then another server only for managers?"
				videoFilePath="05_multiServerAccess.mp4"
			/>
			<VideoSection
				title=""
				description="Here's what the employees and managers will see when they log in."
				videoFilePath="06_serverViews.mp4"
			/>
		</section>
		<section id="creating-api-keys" className={styles.scrollableContent}>
			<VideoSection
				title="Creating API Keys"
				description="API keys allow you to access WireHydra programmatically. We'll create an API key to access the employee server."
				videoFilePath="07_creatingApiKeys.mp4"
			/>
		</section>
		<section id="setting-up-external-auth" className={styles.scrollableContent}>
			<VideoSection
				title="Setting up External Auth"
				description="External Auth allows you to authenticate users against an external system. We'll use ldap.forumsys.com as our LDAP server. We'll test against the users Newton and Boyle, who are in the scientists and chemists groups, respectively."
				videoFilePath="08_ldap_A_setup.mp4"
			/>
			<VideoSection
				title=""
				description="Once we've set up our LDAP pools, users can log in using their LDAP credentials."
				videoFilePath="08_ldap_B_views.mp4"
			/>
		</section>


        <section id="about-admin" className={styles.scrollableContent}>
			<ImageSection
				title="Admin Panel"
				description="The Admin Panel is where you can manage users, external auth, server mounts, permissions, access control, and general settings."
				imagePath="/01_userPanel.gif"
				subtext="Most panels behave the same way, consisting of a table of entries, and the ability to add, edit, and remove items."
			/>
        </section>
		<section id="users" className={styles.scrollableContent}>
			<ImageSection
				title="Users Panel"
				description="The Users Panel is where you manage local users. Local users are hardcoded into WireHydra and do not require connection to any external authenticators."
				imagePath="/04_panels_userPanel.png"
				subtext="Local Users override external auth users. If a user exists in both the local and external auth systems, the local user will be used."
			/>
		</section>
		<section id="external-auth" className={styles.scrollableContent}>
			<ImageSection
				title="LDAP Authentication"
				description="LDAP Authentication communicates with Microsoft Active Directory or Open LDAP servers."
				imagePath="/05_panels_externalAuth.png"
				subtext=""
			/>
			<ImageSection
				title="Radius Authentication"
				description="Radius Authentication communicates with Radius servers."
				imagePath="/05_panels_radiusAuth.png"
				subtext=""
			/>
		</section>
		<section id="server-mounts" className={styles.scrollableContent}>
			<ImageSection
				title="Local Folder Server"
				description="It's easy to mount a local folder as a server, just point WireHydra to the folder you want to share."
				imagePath="06_localMount.png"
				subtext=""
			/>
			<ImageSection
				title="Networked Server"
				description="Mounting an SMB or NFS share requires a bit more setup."
				imagePath="07_smbMount.png"
				subtext=""
			/>
			<ImageSection
				title="Toggling Availability"
				description="If you want to shut down a server temporarily, you can toggle its availability."
				imagePath="/08_toggleAvailability.png"
				subtext="The server will remain mounted, but users will not be able to access it."
			/>
			<ImageSection
				title="Invalid Servers"
				description="If you hit an error when mounting a server or if a networked server becoms unavailable, an 'offline' status should display in the table."
				imagePath="/09_invalidServer.png"
				subtext="You can click on the status to re-test the server."
			/>
		</section>
		<section id="permissions" className={styles.scrollableContent}>
			<ImageSection
				title="Permissions Panel"
				description="Take time to consider what permissions you want to give your users."
				imagePath="/10_permissionsSetup.png"
				subtext="For example, you may want clients to be able to upload & download files, but not delete them."
			/>
			<ImageSection
				title="Custom Rules"
				description="You may want to hide certrain files or folders from certain users without creating entirely separate servers."
				imagePath="/11_customRules.png"
				subtext="For example, you might want to hide WIP files or sensitive data from clients."
			/>
			<ImageSection
				title="Regex Rules"
				description="The regex checkbox allow you to create complex rules for hiding files and folders."
				imagePath="/12_regexRules.png"
				subtext="these examples show a rule that hides all files and folders that start with 'wip' in the '/dynamically/hide' folder and a rule that hides all files with a pdf extension."
			/>
		</section>
		<section id="access-control" className={styles.scrollableContent}>
			<ImageSection
				title="Local User Access Controle"
				description="Setting access for a local user is simple - just select the user and then assign permissions out for each server."
				imagePath="13_accessControl.png"
				subtext="Here we give our 'important client' account client-level access to one of our two servers"
			/>
			<ImageSection
				title="External Auth Access Control"
				description="External Auth users can be assigned access control policies just like local users."
				imagePath=""
				subtext=""
			/>
		</section>
		<section id="general" className={styles.scrollableContent}>
			<ImageSection
				title="General Settings Panel"
				description="The General Settings Panel lets you manage session timeout, account locking, and data transfer rates."
				imagePath="14_generalSettings.png"
				subtext=""
			/>
			<ImageSection
				title="Licensing"
				description="Enter your license key here to activate WireHydra."
				imagePath="15_licensing.png"
				subtext=""
			/>
			<ImageSection
				title="Branding"
				description="The branding panel lets you white-label WireHydra."
				imagePath="16_branding.png"
				subtext="You can change both the Logo and the Name of the application."
			/>
		</section>
		<section id="about-browser" className={styles.scrollableContent}>
			<ImageSection
				title="File Browser"
				description="WireHydra wraps FileBrowser, a simple, clean file hosting tool."
				imagePath="/02_fileBrowser.gif"
				subtext="In FileBrowser, you can view, upload, download, and delete files from any of the server mounts your account has access to."

			/>
		</section>
		<section id="servers-page" className={styles.scrollableContent}>
			<ImageSection
				title="Servers Page"
				description="You can view your servers on the homepage."
				imagePath="/03_serverView_v2.gif"
				subtext="Each server is its own isolated environment. Users can only see the servers they have been given access to."
			/>
		</section>
      </div>
    </div>
  );
}

export default Documentation;
