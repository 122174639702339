import React, { useState } from "react";
import styles from "./CopyToClipboard.module.css";

const CopyToClipboard = ({ text }) => {
	const [isCopied, setIsCopied] = useState(false);
  
	const handleCopy = async () => {
	  try {
		await navigator.clipboard.writeText(text);
		setIsCopied(true);
		setTimeout(() => setIsCopied(false), 2000); // Reset copied status after 2 seconds
	  } catch (error) {
		console.error("Failed to copy text: ", error);
	  }
	};
  
	return (
	  <div className={styles["copy-container"]}>
		<span className={styles["copy-text"]}>{text}</span>
		<button onClick={handleCopy} className={styles["copy-button"]}>
		  {isCopied ? "Copied!" : "Copy"}
		</button>
	  </div>
	);
};

export default CopyToClipboard;