import React, { useEffect, useState } from 'react';
import { getUrl } from 'aws-amplify/storage';
import styles from './VideoSection.module.css';

function VideoSection({ title, description, videoFilePath }) {
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    async function fetchVideo() {
      try {
        const videoObject = { path: videoFilePath };
        const signedUrl = await getUrl(videoObject);
        setVideoUrl(signedUrl); // Use the signed URL as the video source
      } catch (e) {
        console.error('Error fetching video:', e);
      }
    }
    fetchVideo();
  }, [videoFilePath]);

  return (
    <div>
      <h1>{title}</h1>
      <p className={styles.videoSectionDescription}>{description}</p>
      {videoUrl ? (
        <video className={styles.videoPlayer}
          controls
		  preload="none"
          style={{
            
            backgroundColor: 'white', // Set custom background color
            objectFit: 'cover', // Ensures proper aspect ratio for content
          }}
        >
          <source src={videoUrl.url.href} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <p>Loading video...</p>
      )}
    </div>
  );
}

export default VideoSection;
