// src/pages/Resources.js
import "./Resources.css";

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Resources() {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);
	
	  return (
		
	<div className="App">
		<section className="parallax" id="links">
			<ul>
				<li><a href="/downloads">Download Wirehydra</a></li>
				{/* <li><a href="/get-started">Quickstart Guide</a></li> */}
				<li><a href="/documentation">Documentation</a></li>
			</ul>
		</section>
	</div>
  );
}

export default Resources;