import React from 'react';
import { Link } from 'react-router-dom';
import syles from './install.css';
import CodeBlockWithCopy from '../../../components/CodeBlockWithCopy';
function InstallMacosBinary() {
	return (
		<div>
			<h2>Install MacOS Binary</h2>
			<p className='installDescription'>We recommend installing via our shell script, but you can also download the MacOS binary directly from the <Link to="/downloads" className='downloadLink'>downloads</Link> page.</p>
			<p className='installDescription'>To install via shell script, run the following command in your terminal:</p>
			<CodeBlockWithCopy codeText={"/bin/bash -c \"$(curl -fsSL https://files.wirehydra.com/scripts/install.sh)\"" } />
			<p className='installDescription'>This will install the bin so that it is immediately available in your PATH. if you install manually, you may need to supply the full path to WireHydra in each of the following commands.</p>
			<p className='installDescription'>for a quickstart, run the following commands.</p>
			<CodeBlockWithCopy codeText="mkdir ~/wiretest" />
			<CodeBlockWithCopy codeText="wirehydra ~/wiretest -U myfirstuser -P wire123 --demo" />
			<p className='installDescription'>We recommend using a different username and password.</p>
			<p className='installDescription'>The 'demo' flag will mount a server within the wirehydra environment for you to begin testing on.</p>
			<p className='installDescription'>Regardless of install method, when you run the tool you should see the boot animation play</p>
			<img src="/intro_gif_8colorTransparent.gif" alt="boot animation" style={{borderRadius: '15px'}} />
			<p className='installDescription'>Once your server is launched, navigate to the printed web address in your browser, at default settings this will look like:</p>
			<CodeBlockWithCopy codeText="http://127.0.0.1:8000" />
		</div>
	);
}

export default InstallMacosBinary;