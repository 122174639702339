//src/pages/ManageLicense.js
import { useAuthenticator, Authenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useState, useEffect } from 'react';
import { get } from 'aws-amplify/api';
import CopyToClipboard from '../components/CopyToClipboard';
import "./ManageLicense.css";
import { useLicense } from '../context/LicenseContext';


import { useLocation } from 'react-router-dom';


function ManageLicense() {
  const { license, loading, retryFetchLicense } = useLicense();
  const [retryCount, setRetryCount] = useState(0); // State to track retries
  const retryLimit = 3; // Define your retry limit

  const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

  return (
    <div className="App">
      <Authenticator className='loginPanel' initialState={"signIn"}>
        {({ signOut, user }) => {
          // Retry fetching the license once the user is authenticated
          if (user && !license && !loading && retryCount < retryLimit) {
            retryFetchLicense();
            setRetryCount((prevCount) => prevCount + 1); // Increment retry count
          }

          return (
            <div className="license-activation">
              <p>
                This is your license key, add it to the Licensing Panel in WireHydra to begin working.
              </p>
              {loading ? (
                <p>Loading your license...</p>
              ) : (
                <>
                  <CopyToClipboard text={license || "No license found"} />
                  {retryCount >= retryLimit && !license && (
					<div>
						<br></br>
						<p>Retry limit reached, the server may be down. Please try again later.</p>
					</div>
                  )}
                </>
              )}
            </div>
          );
        }}
      </Authenticator>
    </div>
  );
}
  
  export default ManageLicense;