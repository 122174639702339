import React, { useState } from 'react';
import InstallMacosBinary from './tabs/InstallMacosBinary';
import InstallMacosApp from './tabs/InstallMacosApp';
import InstallLinuxBinary from './tabs/InstallLinuxBinary';
import './InstallTabView.css';

function InstallTabView() {
	const [activeTab, setActiveTab] = useState(0);

	const tabs = [
		{ label: 'macOS Binary', content: <InstallMacosBinary />},
		{ label: 'macOS App', content: <InstallMacosApp /> },

	];
	{/* label: 'macOS App', content: <InstallMacosApp /> */}
	{/* label: 'Linux Binary', content: <InstallLinuxBinary /> */}
  
	return (
	  <div>
		{/* Tab Header */}
		<h2>Installation</h2>
		<div className="tab-header">
		  {tabs.map((tab, index) => (
			<button
			  className={activeTab === index ? 'tab-item active-tab-item feature-item' : 'tab-item feature-item'}
			  key={index}
			  onClick={() => setActiveTab(index)}
			>
			  {tab.label}
			</button>
		  ))}
		</div>
  
		{/* Tab Content */}
		<div className="tab-content">
		  {tabs[activeTab].content}
		</div>
	  </div>
	);
}

export default InstallTabView;