// src/App.js

import React from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Home from './pages/Home';
//import Profile from './pages/Profile';
//import GetStarted from './pages/GetStarted';
// import Purchase from './pages/Purchase';
import Resources from './pages/Resources';
import Pricing from './pages/Pricing';
import ManageLicense from './pages/ManageLicense';
import SignIn from './pages/SignIn';
import Header from './components/Header';
import Footer from './components/Footer';

import TroubleShooting from './pages/TroubleShooting';
import Documentation from './pages/Documentation';
import Downloads from './pages/Downloads';
import AppIntegrations from './pages/AppIntegrations';
import { LicenseProvider } from './context/LicenseContext';
import OpenApiDocumentation from './pages/OpenApiDocumentation';


Amplify.configure(awsExports);

function App() {
	//const { user, signOut } = useAuthenticator((context) => [context.user]);
	//const [isAuthenticated, setIsAuthenticated] = React.useState(false);

	// Handle setting user and signOut function inside useEffect
	

	return (
		<div className='wrapper'>
		<Authenticator.Provider>
		<LicenseProvider>
			<Router>
			<Header />
			<div className="content">
				<Routes>
					<Route path="/" element={<Home />} />
					{/* <Route path="/purchase" element={<Purchase />} /> */}
					<Route path="/pricing" element={<Pricing />} />
					<Route path="/license" element={<ManageLicense />} />
					<Route path="/signin" element={<SignIn />} />
					<Route path="/signup" element={<SignIn defaultAuthState="signUp" />} />


					{/* <Route path="/get-started" element={<GetStarted />} /> */}
					<Route path="/resources" element={<Resources />} />
					<Route path="/downloads" element={<Downloads />} />
					<Route path="/documentation" element={<Documentation />} />
					<Route path="/api-documentation" element={<OpenApiDocumentation />} />

				</Routes>
			</div>
			<Footer />
			</Router>
		</LicenseProvider>
		</Authenticator.Provider>
		</div>
	);
}

export default App;
