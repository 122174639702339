import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import "./SwaggerApi.css"

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SwaggerDocs = () => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);
	return (
		<SwaggerUI url="/openapi.json" />
	);
};

export default SwaggerDocs;
