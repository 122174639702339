import React, { createContext, useContext, useState, useEffect } from 'react';
import { get } from 'aws-amplify/api';

const LicenseContext = createContext();

const myApi = "wirehydraapi";
const path = "/user/license";

export function LicenseProvider({ children }) {
  const [license, setLicense] = useState(null);
  const [loading, setLoading] = useState(true);

  async function fetchLicense() {
    setLoading(true);
    try {
      const restOperation = get({
        apiName: myApi,
        path: path,
      });
      const response = await restOperation.response;
      const responseBody = await response.body.json();
      setLicense(responseBody['license']);
      console.log('GET call succeeded: ', responseBody);
    } catch (e) {
      console.log('GET call failed: ', e);
      setLicense(null); // Clear license on failure
    } finally {
      setLoading(false);
    }
  }

  // Retry function that can be called externally
  const retryFetchLicense = () => {
    fetchLicense();
  };

  // Initial fetch when the provider mounts
  useEffect(() => {
    fetchLicense();
  }, []);

  return (
    <LicenseContext.Provider value={{ license, loading, retryFetchLicense }}>
      {children}
    </LicenseContext.Provider>
  );
}

export function useLicense() {
  return useContext(LicenseContext);
}
