//src/pages/Pricing.js

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Pricing() {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);
	
	  return (
	<div className="App">
		<section id="pricing" class="parallax hero">
			<div class="container">
				<h2>BETA Program</h2>
				<p>while we are in beta, all features are free to use.</p>
				<p>Get started by downloading WireHydra today.</p>
				<p>we will be launching our pricing structure soon.</p>
			</div>
		</section>
	</div>
  );
}

export default Pricing;