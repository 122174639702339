import { useAuthenticator, Authenticator } from '@aws-amplify/ui-react';
import SignIn from './SignIn';
import AppIcon from '../components/ClickableImage';
import App from '../App';
import CodeBlockWithCopy from '../components/CodeBlockWithCopy';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Downloads() {

	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return (
		
		<div className="App">
			<section className="parallax hero">
				<h1 style={{ textAlign: 'center', color: 'var(--pink)', paddingTop: '1rem' }}>WireHydra Downloads</h1>
				<p style={{ textAlign: 'center', width: '70%', paddingTop: '1rem', margin: '0 auto', paddingBottom: '1rem' }}>
					WireHydra is currently only available for macOS, but Linux versions will be launched shortly.</p>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flexStart' }} >
				<AppIcon
					imagePath="/bundle.png"
					title="MacOS App"
					description="Quick and easy front-end for launching WireHydra"
					link="https://files.wirehydra.com/bundles/latest"
				/>
				
				<AppIcon
					imagePath="/binary.png"
					title="MacOS Binary"
					description="CLI utility for power users."
					link="https://files.wirehydra.com/binaries/latest"
				/>
				</div>

				<h3 style={{ textAlign: 'center', color: 'var(--pink)', paddingTop: '1rem' }}>Programatic Binary Installation</h3>
				<CodeBlockWithCopy codeText={"/bin/bash -c \"$(curl -fsSL https://files.wirehydra.com/scripts/install.sh)\"" } />
			</section>

			
		</div>
			
		
);
}

export default Downloads;