// src/components/SignOutButton.js

import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './SignOutButton.css';

function SignOutButton({ signOut }) {
  const navigate = useNavigate(); // Initialize navigate

  const handleSignOut = async () => {
    try {
      await signOut(); // Call the signOut function
      navigate('/'); // Redirect to the homepage after signing out
    } catch (error) {
      console.log('Error signing out:', error);
    }
  };

  return (
    <button
      onClick={handleSignOut} // Use handleSignOut function
      className="signout-button"
    >
      Sign Out
    </button>
  );
}

export default SignOutButton;
