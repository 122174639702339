// src/pages/Resources.js
import { Navigate } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import './Authenticator.css'; // Import your custom CSS file

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function SignIn(props) {
	const location = props.location || "/";
	const defaultAuthState = props.defaultAuthState || "signIn";

	const curLocation = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [curLocation]);

	return (
		<Authenticator className='loginPanel' initialState={defaultAuthState}>
			{({ signOut, user }) =>
				<Navigate to={location} />
			}
		</Authenticator>

	);
}

export default SignIn;