import React from 'react';
import './Header.css'; // Import CSS specific to Header
import { useAuthenticator, Authenticator } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';

import SignOutButton from './SignOutButton';
import Dropdown from './Dropdown';

function Header() { // Destructure props
	const { user, signOut } = useAuthenticator((context) => [context.user]);
	
	const resourcesItems = [
		{ label: 'Download Wirehydra', href: '/downloads' },
		// { label: 'Quickstart Guide', href: '/get-started' },
		{ label: 'Documentation', href: '/documentation' },
		{ label: 'API Documentation', href: '/api-documentation' },
	];

	return (
		<header className="header">
		<div className="header-container">
				<Link to="/" className='logo-link'>
					<div className="logo-container">
						<img className='logo-image' src="/logo192.png" width="48" height="48" alt="WireHydra Logo" />
						<div className="logo">
							WireHydra
						</div>
					</div>
				</Link>
				<nav className="nav-links">
					<label htmlFor="menu" tabIndex="0"> Menu </label>
					<input id="menu" type="checkbox" />
					<ul>
						<li>
							<Link to="/documentation">Get Started</Link>
						</li>
						<li className='dropdownItem'>
							<Link to="/downloads">Downloads</Link>
						</li>
						<li className='dropdownItem'>
							<Link to="/api-documentation">API Documentation</Link>
						</li>
						<li className='dropdownList'>
							{/* Use the Dropdown component for Resources */}
							<Dropdown label="Resources" items={resourcesItems} />
						</li>
						{user ? null : <li><Link to="/pricing">Pricing</Link></li>}
						{user ? <li><Link to="/license">Activate</Link></li> : null}
						<li>
						{user ? <SignOutButton signOut={signOut} /> : <Link to="/signin">Sign In</Link>}
						</li>
						<li>
						{user ? null : <Link to="/signup" className='signup'>Sign Up</Link>}
						</li>
					</ul>
				</nav>
			</div>
		</header>
	);
}

export default Header;
