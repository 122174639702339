import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './SideBar.module.css';
import { Link } from 'react-router-dom';

function Sidebar({ links }) {
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // Create a map to determine which sections have subsections
  const sectionsWithSubsections = links.reduce((acc, link) => {
    if (link.type === 'subsection') {
      acc[link.section] = true;
    }
    return acc;
  }, {});

  return (
    <div className={styles.sidebar}>
      <ul>
        {links.map((link, index) => {
          if (link.type === 'section') {
            const hasSubsections = sectionsWithSubsections[link.id];

            return (
              <li key={index}>
                {hasSubsections ? (
                  <div
                    onClick={() => toggleSection(link.id)}
                    className={styles.section}
                    style={{ cursor: 'pointer' }}
                  >
                    {openSections[link.id] ? '▼' : '►'} {link.label}
                  </div>
                ) : (
                  // If no subsections, render as a clickable link
                  <a href={`#${link.id}`} className={styles.section}>
					{link.label}
					</a>
                )}
              </li>
            );
          }

          if (link.type === 'subsection' && openSections[link.section]) {
            return (
              <li key={index} style={{ marginLeft: '20px' }}>
                <a href={`#${link.id}`} className={styles.subsection}>
                  {link.label}
                </a>
              </li>
            );
          }

          return null;
        })}
      </ul>
    </div>
  );
}

Sidebar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['section', 'subsection']).isRequired,
      section: PropTypes.string, // Only for subsections, points to the parent section
    })
  ).isRequired,
};

export default Sidebar;
