import React, { useState } from "react";
import "./CodeBlockWithCopy.css";

const CodeBlockWithCopy = ({ codeText }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(codeText).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    });
  };

  return (
    <div className="code-block-container">
      
      <button title="Copy to clipboard"
        className="copy-button"
        onClick={handleCopy}
      >
        {copied ? "!" : "$"}
      </button>
	  <code>{codeText}</code>
    </div>
  );
};

export default CodeBlockWithCopy;
